/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "installation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#installation",
    "aria-label": "installation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Installation"), "\n", React.createElement(_components.p, null, "Learn how to get xstyled up and running in your project."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "using-styled-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#using-styled-components",
    "aria-label": "using styled components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Using styled-components"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://styled-components.com/"
  }, "styled-components"), " is a good default choice if you don't know about CSS-in-JS libraries."), "\n", React.createElement(_components.p, null, "If you already use styled-components on your project, then you should read ", React.createElement(_components.a, {
    href: "/docs/migrate-from-styled-components/"
  }, "Migrate from styled-components guide"), "."), "\n", React.createElement(_components.h3, {
    id: "install-styled-components-and-xstyled-via-npm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install-styled-components-and-xstyled-via-npm",
    "aria-label": "install styled components and xstyled via npm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install styled-components and xstyled via npm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "npm install styled-components @xstyled/styled-components\n")), "\n", React.createElement(_components.h3, {
    id: "setup-theme-and-preflight",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#setup-theme-and-preflight",
    "aria-label": "setup theme and preflight permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Setup ", React.createElement(_components.code, null, "theme"), " and ", React.createElement(_components.code, null, "Preflight")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// App.js\nimport {\n  defaultTheme,\n  ThemeProvider,\n  Preflight,\n} from '@xstyled/styled-components'\n\nconst theme = {\n  ...defaultTheme,\n  // Customize your theme here\n}\n\nexport default function App() {\n  return (\n    <ThemeProvider theme={theme}>\n      <Preflight />\n      {/* ... */}\n    </ThemeProvider>\n  )\n}\n")), "\n", React.createElement(_components.h3, {
    id: "write-your-first-component",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#write-your-first-component",
    "aria-label": "write your first component permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Write your first component"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { x } from '@xstyled/styled-components'\n\nfunction Button(props) {\n  return <x.button bg=\"blue-500\" {...props} />\n}\n")), "\n", React.createElement(_components.h2, {
    id: "using-emotion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#using-emotion",
    "aria-label": "using emotion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Using Emotion"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://emotion.sh/"
  }, "Emotion"), " is as good as ", React.createElement(_components.a, {
    href: "https://styled-components.com/"
  }, "styled-components"), ", using it is also a good choice!"), "\n", React.createElement(_components.p, null, "If you already use Emotion on your project, then you should read ", React.createElement(_components.a, {
    href: "/docs/migrate-from-emotion/"
  }, "Migrate from Emotion guide"), "."), "\n", React.createElement(_components.h3, {
    id: "install-emotion-and-xstyled-via-npm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install-emotion-and-xstyled-via-npm",
    "aria-label": "install emotion and xstyled via npm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install emotion and xstyled via npm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "npm install @emotion/react @emotion/styled @xstyled/emotion\n")), "\n", React.createElement(_components.h3, {
    id: "setup-theme-and-preflight-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#setup-theme-and-preflight-1",
    "aria-label": "setup theme and preflight 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Setup ", React.createElement(_components.code, null, "theme"), " and ", React.createElement(_components.code, null, "Preflight")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// App.js\nimport { defaultTheme, ThemeProvider, Preflight } from '@xstyled/emotion'\n\nconst theme = {\n  ...defaultTheme,\n  // Customize your theme here\n}\n\nexport default function App() {\n  return (\n    <ThemeProvider theme={theme}>\n      <Preflight />\n      {/* ... */}\n    </ThemeProvider>\n  )\n}\n")), "\n", React.createElement(_components.h3, {
    id: "write-your-first-component-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#write-your-first-component-1",
    "aria-label": "write your first component 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Write your first component"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { x } from '@xstyled/emotion'\n\nfunction Button(props) {\n  return <x.button bg=\"blue-500\" {...props} />\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
